<template>
	<div class="wrapper">
		<side-bar>
			<template v-slot:links>
				<div v-for="(content, contenIdx) in sideBarContents" :key="contenIdx">
					<hr class="my-3" />
					<div class="d-flex flex-row justify-content-between">
						<div class="card-title pointer" @click="onToggle(contenIdx)">
							{{ content.title }}
						</div>
						<div
							class="d-flex justify-content-center align-items-center pb-3"
							@click="onToggle(contenIdx)"
						>
							<img
								v-if="content.isOpen"
								class="toggle-icon"
								:src="require('@/assets/Icon/down_btn_icon.svg')"
								alt="toggle-btn"
							/>
							<img
								v-else
								class="toggle-icon"
								:src="require('@/assets/Icon/right_btn_icon.svg')"
								alt="toggle-btn"
							/>
						</div>
					</div>

					<b-collapse :id="`collapse-${contenIdx}`">
						<div v-for="(item, itemIdx) in content.items" :key="itemIdx">
							<sidebar-item
								:link="{
									name: item.name,
									icon: item.icon,
									path: item.path,
								}"
							/>
						</div>
					</b-collapse>
				</div>
				<div class="sideBar-content">
					<hr class="my-3" />
					<sidebar-item
						class="sideBar-item"
						:link="{
							name: 'NFT 登録履歴',
							path: 'nft',
						}"
					/>
				</div>
			</template>
			<div class="user-info d-flex">
				<div class="user-avatar-wrapper">
					<img
						v-if="profilePic != undefined"
						class="user-avatar"
						:src="avatar"
						alt=""
						@click="onAvatarClick"
					/>
					<img class="camera-icon" :src="require('@/assets/Icon/camera.svg')" />

					<input
						type="file"
						ref="fileInput"
						@change="onUploadFile"
						v-show="false"
						accept="image/png, image/jpeg"
					/>
				</div>

				<div class="user-name">
					<div>{{ userInfo.register_name }}</div>
					<img
						@click="onLogout"
						class="logout-icon"
						:src="require('@/assets/Icon/Logout.svg')"
						alt=""
					/>
				</div>
			</div>
		</side-bar>
		<div class="main-content">
			<DashBoardNavBar />
			<div @click="$sidebar.displaySidebar(false)">
				<fade-transition :duration="200" origin="center top" mode="out-in">
					<!-- your content here -->
					<router-view></router-view>
				</fade-transition>
			</div>
		</div>
		<NotificationModal
			:notiContent="logoutNotiContent"
			:notificationModalId="logoutNotiId"
			@on-click="onConfirmLogout"
		>
		</NotificationModal>
	</div>
</template>
<script>
/* eslint-disable no-new */
import DashBoardNavBar from '../DashboardNavbar.vue';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { mapState } from 'vuex';
const EXPIRATION = 31536000;

function hasElement(className) {
	return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
	if (hasElement(className)) {
		new PerfectScrollbar(`.${className}`);
	} else {
		// try to init it later in case this component is loaded async
		setTimeout(() => {
			initScrollbar(className);
		}, 100);
	}
}
import { FadeTransition } from 'vue2-transitions';
import userService from '../../../services/user.service';
import TransferService from '../../../services/transfer.service';

import NotificationModal from '@/components/Modal/NotificationModal/NotificationModal.vue';
export default {
	data() {
		return {
			logoutNotiId: 'logoutNoti',
			logoutNotiContent: 'ログアウトしますか？',
			profilePic: undefined,
			sideBarContents: [
				{
					title: '案件管理',
					items: [
						{
							name: '進行中の案件',
							icon: '',
							path: 'projectmenu',
						},
						{
							name: '案件検索',
							icon: '',
							path: 'searchproject',
						},
						{
							name: '案件登録',
							icon: 'fas fa-plus-circle text-white',
							path: 'registerproject',
						},
					],
					isOpen: false,
				},
				{
					title: '各種設定',
					items: [
						{
							name: 'マイページ',
							icon: '',
							path: 'mypage',
						},
						{
							name: 'クライアント検索',
							icon: '',
							path: 'searchclient',
						},
						{
							name: 'クライアント登録',
							icon: 'fas fa-plus-circle text-white',
							path: 'createclient',
						},
						{
							name: 'お問い合わせ',
							icon: '',
							path: 'contact',
						},
					],
					isOpen: false,
				},
			],
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState(['userInfo']),
		avatar() {
			if (this.profilePic != '') {
				return this.profilePic;
			} else {
				return require('@/assets/Avatar/NewAccountAvatar.png');
			}
		},
	},
	components: {
		FadeTransition,
		DashBoardNavBar,
		NotificationModal,
	},
	methods: {
		onAvatarClick() {
			this.$refs.fileInput.click();
		},
		onUploadFile(event) {
			let file = event.target.files[0];
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = async () => {
				let [uploadResponse, errorPostImg] =
					await await TransferService.postProfilePicture({
						content: reader.result.toString(),
						file_name: file.name,
						user_id: this.userInfo.id,
						sub_folder: 'avatar',
					});
				if (uploadResponse) {
					let key = uploadResponse.key;
					this.updateUserProfilePicture(key);
				} else {
					console.log(errorPostImg);
				}
			};
		},
		async updateUserProfilePicture(key) {
			let [updateResponse, errorUpdate] = await await userService.update(
				this.userInfo.id,
				{
					picture_key: key,
				}
			);
			if (updateResponse) {
				this.profilePic = await this.getFileUrl(key, EXPIRATION);
			} else {
				console.log(errorUpdate);
			}
		},
		async getFileUrl(key, expiration) {
			try {
				if (!key) {
					return '';
				}
				let response = await TransferService.get(key, expiration);

				console.log('%c Get image url successfully!', 'color: red');
				return response[0].link;
			} catch (error) {
				console.log(error);
				return '';
			}
		},
		onToggle(contenIdx) {
			this.sideBarContents[contenIdx]['isOpen'] =
				!this.sideBarContents[contenIdx]['isOpen'];
			let toggleElm = 'collapse-' + contenIdx;
			this.$root.$emit('bv::toggle::collapse', toggleElm);
		},

		initScrollbar() {
			let isWindows = navigator.platform.startsWith('Win');
			if (isWindows) {
				initScrollbar('sidenav');
			}
		},
		async onConfirmLogout() {
			let [response, error] = await await userService.logout();
			console.log([response, error]);
			if (response) {
				this.$router.push('/adminApp/login');
			}
		},
		async onLogout() {
			this.$bvModal.show(this.logoutNotiId);
		},
	},
	async mounted() {
		this.initScrollbar();
		this.profilePic = await this.getFileUrl(this.userInfo.picture_key, EXPIRATION);
	},
};
</script>
<style src="./Menu.scss" lang="scss" scoped></style>
