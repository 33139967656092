var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('side-bar',{scopedSlots:_vm._u([{key:"links",fn:function(){return [_vm._l((_vm.sideBarContents),function(content,contenIdx){return _c('div',{key:contenIdx},[_c('hr',{staticClass:"my-3"}),_c('div',{staticClass:"d-flex flex-row justify-content-between"},[_c('div',{staticClass:"card-title pointer",on:{"click":function($event){return _vm.onToggle(contenIdx)}}},[_vm._v(" "+_vm._s(content.title)+" ")]),_c('div',{staticClass:"d-flex justify-content-center align-items-center pb-3",on:{"click":function($event){return _vm.onToggle(contenIdx)}}},[(content.isOpen)?_c('img',{staticClass:"toggle-icon",attrs:{"src":require('@/assets/Icon/down_btn_icon.svg'),"alt":"toggle-btn"}}):_c('img',{staticClass:"toggle-icon",attrs:{"src":require('@/assets/Icon/right_btn_icon.svg'),"alt":"toggle-btn"}})])]),_c('b-collapse',{attrs:{"id":("collapse-" + contenIdx)}},_vm._l((content.items),function(item,itemIdx){return _c('div',{key:itemIdx},[_c('sidebar-item',{attrs:{"link":{
								name: item.name,
								icon: item.icon,
								path: item.path,
							}}})],1)}),0)],1)}),_c('div',{staticClass:"sideBar-content"},[_c('hr',{staticClass:"my-3"}),_c('sidebar-item',{staticClass:"sideBar-item",attrs:{"link":{
						name: 'NFT 登録履歴',
						path: 'nft',
					}}})],1)]},proxy:true}])},[_c('div',{staticClass:"user-info d-flex"},[_c('div',{staticClass:"user-avatar-wrapper"},[(_vm.profilePic != undefined)?_c('img',{staticClass:"user-avatar",attrs:{"src":_vm.avatar,"alt":""},on:{"click":_vm.onAvatarClick}}):_vm._e(),_c('img',{staticClass:"camera-icon",attrs:{"src":require('@/assets/Icon/camera.svg')}}),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"fileInput",attrs:{"type":"file","accept":"image/png, image/jpeg"},on:{"change":_vm.onUploadFile}})]),_c('div',{staticClass:"user-name"},[_c('div',[_vm._v(_vm._s(_vm.userInfo.register_name))]),_c('img',{staticClass:"logout-icon",attrs:{"src":require('@/assets/Icon/Logout.svg'),"alt":""},on:{"click":_vm.onLogout}})])])]),_c('div',{staticClass:"main-content"},[_c('DashBoardNavBar'),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1),_c('NotificationModal',{attrs:{"notiContent":_vm.logoutNotiContent,"notificationModalId":_vm.logoutNotiId},on:{"on-click":_vm.onConfirmLogout}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }